import { Theme } from '@mui/material/styles';

const Accordion = (theme: Theme) => {
  return {
    MuiAccordion: {
      defaultProps: { disableGutters: true, elevation: 1 },
      styleOverrides: {
        root: {
          '& .MuiAccordionSummary-content': {
            margin: 0,
            display: 'flex',
            gap: '24px',
            alignItems: 'center',
          },
          '& .MuiAccordionSummary-expandIconWrapper': {
            padding: '0 24px',
          },
        },
      },
      variants: [
        {
          props: { variant: 'card' },
          style: {
            borderRadius: '24px !important',
            transition: 'background 0.15s',
            minWidth: 'fit-content',
            [theme.breakpoints.down('sm')]: {
              borderRadius: '16px !important',
            },
            '&:before': {
              display: 'none',
            },
            '& .MuiAccordionSummary-root ': {
              padding: '16px',
              [theme.breakpoints.down('sm')]: {
                padding: '8px',
              },
            },
            '& .MuiAccordionDetails-root': {
              padding: 0,
              paddingBottom: '16px',
            },
            '&:hover': {
              backgroundColor: theme.palette.background.paper + '40',
            },
            '&:active': {
              backgroundColor: theme.palette.background.paper + '80',
            },
            '&.Mui-expanded:hover': {
              backgroundColor: theme.palette.background.paper,
            },
            '&.Mui-expanded:active': {
              backgroundColor: theme.palette.background.paper,
            },
            '& .MuiCardMedia-root': {
              height: '96px',
              width: '96px !important',
              [theme.breakpoints.down('sm')]: {
                height: 48,
                width: '48px !important',
              },
              flexGrow: 0,
              borderRadius: '8px',
              borderWidth: '2px',
              borderStyle: 'solid',
              borderColor: theme.palette.background.paper,
              backgroundColor: theme.palette.background.paper,
              transition: 'transform 0.15s',
            },
            '& .MuiAccordionSummary-expandIconWrapper': {
              [theme.breakpoints.down('sm')]: {
                padding: '0 8px',
              },
            },
          },
        },
        {
          props: { variant: 'card', elevation: 0 },
          style: {
            '&:hover': {
              backgroundColor: theme.palette.divider,
            },
            '&:active': {
              backgroundColor: theme.palette.background.default,
            },
            '&.Mui-expanded:hover': {
              backgroundColor: theme.palette.background.default,
            },
            '&.Mui-expanded:active': {
              backgroundColor: theme.palette.background.default,
            },
          },
        },
      ],
    },
  };
};

export default Accordion;
