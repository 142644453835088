const Palette = {
  customer: {
    text: {
      primary: '#0B1621',
      secondary: '#0B162199',
      disabled: '#0B162140',
    },
    primary: {
      main: '#064550',
      dark: '#043841',
      light: '#1F5862',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#00a69a0d',
      dark: '#00a69a1a',
      light: '#00a69a00',
      contrastText: '#064550',
    },
    success: {
      main: '#00a69a',
      dark: '#068A85',
      light: '#26B3A9',
      contrastText: '#ffffff',
    },
    error: {
      main: '#D83F3F',
      dark: '#A13131',
      light: '#DE5C5C',
      contrastText: '#FFFFFF',
    },
    warning: {
      main: '#DF783C',
      dark: '#BF5C23',
      light: '#E07F47',
      contrastText: '#FFFFFF',
    },
    info: {
      main: '#26559C',
      dark: '#164080',
      light: '#3C66A6',
      contrastText: '#FFFFFF',
    },
    white: {
      main: '#ffffff',
      dark: '#ffffff',
      light: '#ffffff',
      contrastText: '#0B1621',
    },
    background: {
      default: '#00a69a0d',
      paper: '#ffffff',
      paper1: '#FCFAFF',
      nav: '#f2fbfa',
      canvas: '#e5f6f5',
    },
    action: {
      active: '#0B1621',
      disabled: '#06455040',
      disabledBackground: '#00a69a0d',
      hover: '#00a69a0d',
      focus: '#00a69a1a',
      select: '#00a69a26',
    },
    divider: '#00a69a1a',
  },
  partner: {
    text: {
      primary: '#FFFFFF',
      secondary: '#FFFFFF99',
      disabled: '#FFFFFF40',
    },
    primary: {
      main: '#CCEDEB',
      dark: '#99DBD7',
      light: '#F5FBFB',
      contrastText: '#0B1621',
    },
    secondary: {
      main: '#ffffff0d',
      dark: '#ffffff1a',
      light: '#ffffff00',
      contrastText: '#FFFFFF',
    },
    success: {
      main: '#00a69a',
      dark: '#068A85',
      light: '#26B3A9',
      contrastText: '#ffffff',
    },
    error: {
      main: '#D83F3F',
      dark: '#A13131',
      light: '#DE5C5C',
      contrastText: '#FFFFFF',
    },
    warning: {
      main: '#DF783C',
      dark: '#BF5C23',
      light: '#E07F47',
      contrastText: '#FFFFFF',
    },
    info: {
      main: '#26559C',
      dark: '#164080',
      light: '#3C66A6',
      contrastText: '#FFFFFF',
    },
    white: {
      main: '#ffffff',
      dark: '#ffffffDF',
      light: '#ffffff',
      contrastText: '#0B1621',
    },
    background: {
      default: '#ffffff0d',
      paper: '#0B1621',
      nav: '#111C27',
      canvas: '#17222c',
    },
    action: {
      active: '#ffffff',
      disabled: '#ffffff99',
      disabledBackground: '#ffffff0d',
      hover: '#ffffff0d',
      focus: '#ffffff1a',
      select: '#ffffff26',
    },
    divider: '#ffffff1a',
  },
};

export default Palette;
