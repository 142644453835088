import { Theme } from '@mui/material/styles';

const Card = (theme: Theme) => {
  return {
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: '24px',
          transition: 'background 0.15s',
          background: theme.palette.background.paper,
          height: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          '& > *': {
            margin: '8px 16px',
            width: 'calc(100% - 32px)',
          },
          '& > *:first-child': {
            marginTop: '16px',
          },
          '& > *:last-child': {
            padding: '0 !important',
            marginBottom: '16px',
          },
          '& .MuiCardActionArea-root': {
            height: '100%',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            margin: 0,
            '& > *': {
              margin: '8px 16px',
              width: 'calc(100% - 32px)',
            },
            '& > *:first-child': {
              marginTop: '16px',
            },
            '& > *:nth-last-child(3)': {
              marginBottom: '16px',
            },
            '& .MuiCardActionArea-focusHighlight': {
              margin: 0,
              background: 'transparent',
              width: '100%',
              height: '100%',
            },
            '& .MuiTouchRipple-root': {
              margin: 0,
              width: '100%',
              height: '100%',
            },
            '& .MuiTouchRipple-ripple': {
              color: theme.palette.primary.main,
            },
          },
          '& .MuiCardMedia-root': {
            borderRadius: '8px',
            borderWidth: '2px',
            borderStyle: 'solid',
            borderColor: theme.palette.background.paper,
            backgroundColor: theme.palette.divider,
            aspectRatio: 1,
            overflow: 'hidden',
            position: 'relative',
          },
          '& .MuiCardContent-root': {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'space-between',
            gap: '4px',
            padding: '0 4px',
          },
          '& .MuiCardHeader-root': {
            gap: '16px',
            alignItems: 'center',
          },
          '& .MuiCardHeader-title': {
            fontSize: '18px',
            fontWeight: '500',
            lineHeight: '115%',
          },
          '&.hover:hover': {
            background: theme.palette.background.paper + '80',
          },
          '&.hoverWhite:hover': {
            color: theme.palette.background.paper,
            background: theme.palette.background.paper + '1a',
            '& .MuiLinearProgress-root': {
              background: theme.palette.background.paper + '1a',
            },
            '& .MuiLinearProgress-bar': {
              background: theme.palette.background.paper,
            },
            '& .MuiTypography-root': {
              color: theme.palette.background.paper,
            },
          },
          '& .MuiCardActions-root': {
            padding: 0,
          },
        },
      },
      variants: [
        {
          props: { variant: 'info' },
          style: {
            borderRadius: '16px',
            '& .MuiCardMedia-root': {
              width: '48px',
              height: '48px',
              aspectRatio: 1,
              overflow: 'hidden',
              position: 'relative',
            },
            '& .MuiCardHeader-root': {
              padding: '8px',
              width: '100%',
              flexGrow: 0,
              display: 'flex',
              flexDirection: 'row',
              margin: '0 !important',
            },
            '& .MuiCardHeader-title': {
              flexGrow: 1,
            },
            '& .MuiCardHeader-action': {
              margin: 0,
              flexGrow: 0,
            },
            '& .MuiCardHeader-avatar': {
              flexGrow: 0,
              margin: 0,
              display: 'flex',
              '& .MuiCardMedia-root': {
                borderWidth: '2px',
                borderColor: theme.palette.background.paper,
                borderRadius: '8px',
                backgroundColor: theme.palette.divider,
                aspectRatio: 1,
                overflow: 'hidden',
                position: 'relative',
              },
              '&:hover :nth-child(n)': {
                opacity: 0.2,
              },
              '& :nth-child(n)': {
                marginLeft: '-24px',
                transition: 'opacity 0.2s, transform 0.2s',
                '&:hover': {
                  zIndex: 10,
                  transform: 'translateY(-2px)',
                  opacity: 1,
                },
              },
              '& :first-child': {
                marginLeft: 0,
              },
            },
            '& .MuiCardContent-root': {
              flexGrow: 1,
              flexShrink: 1,
              height: '100%',
              justifyContent: 'center',
              marginBottom: '32px',
            },
          },
        },
        {
          props: { variant: 'infoLarge' },
          style: {
            '& .MuiCardMedia-root': {
              width: '64px',
              height: '64px',
              aspectRatio: 1,
              overflow: 'hidden',
              position: 'relative',
            },
            '& .MuiCardHeader-root': {
              padding: 0,
              flexGrow: 0,
            },
            '& .MuiCardHeader-action': {
              margin: 0,
            },
            '& .MuiCardHeader-avatar': {
              margin: 0,
              display: 'flex',
              '& .MuiCardMedia-root': {
                borderWidth: '2px',
                borderColor: theme.palette.background.paper,
                borderRadius: '8px',
                aspectRatio: 1,
                overflow: 'hidden',
                position: 'relative',
              },
              '&:hover :nth-child(n)': {
                opacity: 0.2,
              },
              '& :nth-child(n)': {
                marginLeft: '-24px',
                transition: 'opacity 0.2s, transform 0.2s',
                '&:hover': {
                  zIndex: 10,
                  transform: 'translateY(-2px)',
                  opacity: 1,
                },
              },
              '& :first-child': {
                marginLeft: 0,
              },
            },
            '& .MuiCardContent-root': {
              flexGrow: 1,
              height: '100%',
              justifyContent: 'space-between',
            },
          },
        },
        {
          props: { variant: 'vacant' },
          style: {
            minHeight: '256px',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.background.default,
            transition: 'background 0.15s',
            cursor: 'pointer',
            '&.hover:hover': {
              background: theme.palette.divider,
            },
            '& > *': {
              margin: '0 16px',
            },
            '& .MuiCardActionArea-root': {
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
              cursor: 'pointer',
              '& > *': {
                margin: '0 16px',
              },
            },
          },
        },

        {
          props: { variant: 'line' },
          style: {
            flexDirection: 'row',
            width: '100%',
            maxWidth: '100%',
            height: 'fit-content',
            minHeight: 'fit-content',
            alignItems: 'center',
            display: 'flex',
            [theme.breakpoints.down('sm')]: {
              borderRadius: '16px',
            },
            '& > *': {
              margin: '16px 8px',
              width: 'auto',
              [theme.breakpoints.down('sm')]: {
                margin: '8px 0px',
              },
            },
            '& > *:first-child': {
              marginLeft: '16px',
            },
            '& > *:last-child': {
              marginRight: '16px',
            },
            '& .MuiCardMedia-root': {
              height: 96,
              width: 96,
              minHeight: 96,
              minWidth: 96,
              flexGrow: 0,
              aspectRatio: 1,
              overflow: 'hidden',
              position: 'relative',
              [theme.breakpoints.down('sm')]: {
                height: 48,
                width: 48,
                minHeight: 48,
                minWidth: 48,
                margin: '8px',
              },
            },
            '& .MuiCardContent-root': {
              flexGrow: 1,
              width: '100%',
            },
            '& .MuiCardActions-root': {
              width: 'fit-content',
              flexGrow: 0,
              margin: '0 24px 0 8px ',
              [theme.breakpoints.down('sm')]: {
                margin: '0 8px ',
              },

              '& .MuiButton-root': {
                whiteSpace: 'nowrap',
              },
            },
          },
        },
        {
          props: { variant: 'lineSmall' },
          style: {
            flexDirection: 'row',
            width: '100%',
            maxWidth: '100%',
            height: 'fit-content',
            minHeight: 'fit-content',
            alignItems: 'center',
            display: 'flex',
            '& > *': {
              margin: '16px 8px',
              width: 'auto',
            },
            '& > *:first-child': {
              marginLeft: '16px',
            },
            '& > *:last-child': {
              marginRight: '16px',
            },
            '& .MuiCardMedia-root': {
              margin: '8px 0 8px 8px ',
              height: 48,
              width: 48,
              minHeight: 48,
              minWidth: 48,
              [theme.breakpoints.down('sm')]: {
                height: 24,
                width: 24,
                minHeight: 24,
                minWidth: 24,
              },
            },
            '& .MuiCardContent-root': {
              flexGrow: 1,
              width: '100%',
            },
            '& .MuiCardActions-root': {
              width: 'fit-content',
              flexGrow: 0,
              margin: '0 16px 0 0 ',
              '& .MuiButton-root': {
                whiteSpace: 'nowrap',
              },
            },
            borderRadius: '16px',
          },
        },
        {
          props: { elevation: 0 },
          style: {
            backgroundColor: theme.palette.background.default,
          },
        },

        {
          props: { color: 'white', elevation: 0 },
          style: {
            backgroundColor: theme.palette.background.paper + '0d',
            color: theme.palette.background.paper,
          },
        },
      ],
    },
  };
};

export default Card;
