import { Theme } from '@mui/material/styles';

const Table = (theme: Theme) => {
  return {
    MuiTableContainer: {
      defaultProps: {},
      styleOverrides: {
        root: { padding: '8px' },
      },
    },
    MuiTable: {
      defaultProps: {},
      styleOverrides: {
        root: {},
      },
    },
    MuiTableHead: {
      defaultProps: {
        hover: true,
      },
      styleOverrides: {
        root: {
          '& .MuiTableCell-root': {
            fontWeight: 500,
            border: 'none',
          },
        },
      },
    },
    MuiTableRow: {
      defaultProps: {
        hover: true,
      },
      styleOverrides: {
        root: {
          '& > *:first-child': {
            fontWeight: '400',
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontWeight: 300,
          border: 'none',
        },
      },
      variants: [
        {
          props: { variant: 'stack' },
          style: {
            '& .MuiTableCell-root': {
              [theme.breakpoints.down('sm')]: {
                padding: '2px 16px',
                display: 'block',
                textAlign: 'left',
              },
            },

            [theme.breakpoints.down('sm')]: {
              display: 'block',
              overflow: 'hidden',
              '& > *:first-child': {
                fontWeight: '400',
                paddingTop: '12px',
              },
              '& > *:last-child': {
                paddingBottom: '12px',
              },
            },
          },
        },
      ],
    },
  };
};

export default Table;
