import { Theme } from '@mui/material/styles';

const Chip = (theme: Theme) => {
  return {
    MuiChip: {
      defaultProps: {
        size: 'small',
      },
      styleOverrides: {
        root: {
          '& .MuiSvgIcon-root': {
            color: 'inherit',
          },
        },
      },
      variants: [
        {
          props: { color: 'default' },
          style: {
            background: theme.palette.background.default,
            color: theme.palette.primary.main,
            '& .MuiSvgIcon-root': {
              color: theme.palette.primary.main,
            },
          },
        },
        {
          props: { color: 'white' },
          style: {
            backgroundColor: theme.palette.white.main + '40',
            color: theme.palette.white.main,
          },
        },
        {
          props: { size: 'small' },
          style: {
            borderRadius: '0px',
          },
        },
        {
          props: { size: 'medium' },
          style: {
            borderRadius: '8px',
          },
        },
      ],
    },
  };
};

export default Chip;
