import { Theme } from '@mui/material/styles';

const TextField = (theme: Theme) => {
  return {
    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
      },

      styleOverrides: {
        root: {
          border: 'none',

          '& .MuiInputBase-root': {
            background: theme.palette.background.default,
            borderRadius: '16px',
            '& .MuiOutlinedInput-notchedOutline': {
              borderStyle: 'solid',
              borderWidth: '2px',
              borderColor: 'transparent',
            },
          },
          '& .MuiInputBase-root:hover': {
            '& .MuiOutlinedInput-notchedOutline': {
              background: theme.palette.divider,
              borderColor: 'transparent',
            },
          },
          '& .MuiInputBase-root.Mui-focused': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: theme.palette.primary.main,
            },
          },

          '& .MuiInputLabel-shrink': {
            opacity: 0,
          },
          '& .MuiInputLabel-shrink.Mui-disabled ': {
            opacity: 1,
          },
          '& .MuiInputLabel-shrink.Mui-focused': {
            opacity: 1,
          },
          '& .MuiInputLabel-shrink.Mui-error': {
            opacity: 1,
          },

          //error variant
          '& .MuiInputBase-root.Mui-focused.Mui-error': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: theme.palette.error.main,
            },
          },
          '& .MuiInputBase-root:hover.Mui-error': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: theme.palette.error.main,
            },
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontWeight: 400,
          '&.Mui-focused': {
            fontWeight: 500,
          },
        },
      },
    },
  };
};

export default TextField;
