import { Theme } from '@mui/material/styles';

const IconButton = (theme: Theme) => {
  return {
    MuiIconButton: {
      styleOverrides: {
        root: {},
      },
      variants: [
        {
          props: { size: 'large' },
          style: {
            height: '64px',
            width: '64px',
            minHeight: '64px',
            minWidth: '64px',
            borderRadius: '16px',
          },
        },
        {
          props: { size: 'medium' },
          style: {
            height: '48px',
            width: '48px',
            borderRadius: '8px',
          },
        },
        {
          props: { size: 'small' },
          style: {
            borderRadius: '4px',
            height: '32px',
            width: '32px',
          },
        },
        {
          props: { color: 'primary' },
          style: {
            background: theme.palette.primary.main,
            '& .MuiSvgIcon-root': { fill: theme.palette.primary.contrastText },
            ':hover': {
              background: theme.palette.primary.dark,
            },
          },
        },
        {
          props: { color: 'secondary' },
          style: {
            background: theme.palette.background.default,
            '& .MuiSvgIcon-root': { fill: theme.palette.primary.main },
            ':hover': {
              background: 'ffffff' + '1a',
            },
          },
        },
      ],
    },
  };
};

export default IconButton;
