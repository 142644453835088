import { Theme } from '@mui/material/styles';

const Paper = (theme: Theme) => {
  return {
    MuiPaper: {
      defaultProps: { elevation: 1 },
      styleOverrides: {
        root: {},
      },
      variants: [
        {
          props: { elevation: 0 },
          style: {
            backgroundColor: theme.palette.background.default,
          },
        },
        {
          props: { elevation: 1 },
          style: {
            backgroundColor: theme.palette.background.paper1,
          },
        },
        {
          props: { elevation: 2 },
          style: {
            backgroundColor: theme.palette.background.paper,
          },
        },
        {
          props: { elevation: 3 },
          style: {
            backgroundColor: theme.palette.background.paper,
            outlineStyle: 'solid',
            outlineColor: theme.palette.background.default,
            outlineWidth: '2px',
          },
        },
      ],
    },
  };
};

export default Paper;
